exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-jobs-html-tsx": () => import("./../../../src/pages/jobs.html.tsx" /* webpackChunkName: "component---src-pages-jobs-html-tsx" */),
  "component---src-pages-jobs-tsx": () => import("./../../../src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-templates-news-jsx-content-file-path-src-content-news-bettergraph-mdx": () => import("./../../../src/templates/news.jsx?__contentFilePath=/builds/website/src/content/news/bettergraph.mdx" /* webpackChunkName: "component---src-templates-news-jsx-content-file-path-src-content-news-bettergraph-mdx" */),
  "component---src-templates-news-jsx-content-file-path-src-content-news-betteromics-series-a-mdx": () => import("./../../../src/templates/news.jsx?__contentFilePath=/builds/website/src/content/news/betteromics-series-a.mdx" /* webpackChunkName: "component---src-templates-news-jsx-content-file-path-src-content-news-betteromics-series-a-mdx" */),
  "component---src-templates-news-jsx-content-file-path-src-content-news-clinical-research-collaborative-mdx": () => import("./../../../src/templates/news.jsx?__contentFilePath=/builds/website/src/content/news/clinical-research-collaborative.mdx" /* webpackChunkName: "component---src-templates-news-jsx-content-file-path-src-content-news-clinical-research-collaborative-mdx" */),
  "component---src-templates-news-jsx-content-file-path-src-content-news-data-artistry-precision-biomarker-discovery-mdx": () => import("./../../../src/templates/news.jsx?__contentFilePath=/builds/website/src/content/news/data-artistry-precision-biomarker-discovery.mdx" /* webpackChunkName: "component---src-templates-news-jsx-content-file-path-src-content-news-data-artistry-precision-biomarker-discovery-mdx" */),
  "component---src-templates-news-jsx-content-file-path-src-content-news-eliminate-data-silos-with-betteromics-mdx": () => import("./../../../src/templates/news.jsx?__contentFilePath=/builds/website/src/content/news/eliminate-data-silos-with-betteromics.mdx" /* webpackChunkName: "component---src-templates-news-jsx-content-file-path-src-content-news-eliminate-data-silos-with-betteromics-mdx" */),
  "component---src-templates-news-jsx-content-file-path-src-content-news-federated-learning-mdx": () => import("./../../../src/templates/news.jsx?__contentFilePath=/builds/website/src/content/news/federated-learning.mdx" /* webpackChunkName: "component---src-templates-news-jsx-content-file-path-src-content-news-federated-learning-mdx" */),
  "component---src-templates-news-jsx-content-file-path-src-content-news-gen-ai-drug-safety-mdx": () => import("./../../../src/templates/news.jsx?__contentFilePath=/builds/website/src/content/news/gen-ai-drug-safety.mdx" /* webpackChunkName: "component---src-templates-news-jsx-content-file-path-src-content-news-gen-ai-drug-safety-mdx" */),
  "component---src-templates-news-jsx-content-file-path-src-content-news-llm-assisted-clinical-data-abstraction-mdx": () => import("./../../../src/templates/news.jsx?__contentFilePath=/builds/website/src/content/news/llm-assisted-clinical-data-abstraction.mdx" /* webpackChunkName: "component---src-templates-news-jsx-content-file-path-src-content-news-llm-assisted-clinical-data-abstraction-mdx" */),
  "component---src-templates-news-jsx-content-file-path-src-content-news-llm-platform-regulated-data-mdx": () => import("./../../../src/templates/news.jsx?__contentFilePath=/builds/website/src/content/news/llm-platform-regulated-data.mdx" /* webpackChunkName: "component---src-templates-news-jsx-content-file-path-src-content-news-llm-platform-regulated-data-mdx" */),
  "component---src-templates-news-jsx-content-file-path-src-content-news-molecular-twin-mdx": () => import("./../../../src/templates/news.jsx?__contentFilePath=/builds/website/src/content/news/molecular-twin.mdx" /* webpackChunkName: "component---src-templates-news-jsx-content-file-path-src-content-news-molecular-twin-mdx" */),
  "component---src-templates-news-jsx-content-file-path-src-content-news-multiomics-data-mdx": () => import("./../../../src/templates/news.jsx?__contentFilePath=/builds/website/src/content/news/multiomics-data.mdx" /* webpackChunkName: "component---src-templates-news-jsx-content-file-path-src-content-news-multiomics-data-mdx" */),
  "component---src-templates-news-jsx-content-file-path-src-content-news-nature-article-cedars-sinai-molecular-twin-project-mdx": () => import("./../../../src/templates/news.jsx?__contentFilePath=/builds/website/src/content/news/nature-article-cedars-sinai-molecular-twin-project.mdx" /* webpackChunkName: "component---src-templates-news-jsx-content-file-path-src-content-news-nature-article-cedars-sinai-molecular-twin-project-mdx" */),
  "component---src-templates-news-jsx-content-file-path-src-content-news-pharma-cloud-hitrust-mdx": () => import("./../../../src/templates/news.jsx?__contentFilePath=/builds/website/src/content/news/pharma-cloud-HITRUST.mdx" /* webpackChunkName: "component---src-templates-news-jsx-content-file-path-src-content-news-pharma-cloud-hitrust-mdx" */),
  "component---src-templates-news-jsx-content-file-path-src-content-news-pharma-cloud-security-mdx": () => import("./../../../src/templates/news.jsx?__contentFilePath=/builds/website/src/content/news/pharma-cloud-security.mdx" /* webpackChunkName: "component---src-templates-news-jsx-content-file-path-src-content-news-pharma-cloud-security-mdx" */),
  "component---src-templates-news-jsx-content-file-path-src-content-news-real-time-insights-manufacturing-mdx": () => import("./../../../src/templates/news.jsx?__contentFilePath=/builds/website/src/content/news/real-time-insights-manufacturing.mdx" /* webpackChunkName: "component---src-templates-news-jsx-content-file-path-src-content-news-real-time-insights-manufacturing-mdx" */),
  "component---src-templates-news-jsx-content-file-path-src-content-news-scientific-queries-with-betteromics-ai-assistant-mdx": () => import("./../../../src/templates/news.jsx?__contentFilePath=/builds/website/src/content/news/scientific-queries-with-betteromics-ai-assistant.mdx" /* webpackChunkName: "component---src-templates-news-jsx-content-file-path-src-content-news-scientific-queries-with-betteromics-ai-assistant-mdx" */),
  "component---src-templates-news-jsx-content-file-path-src-content-news-unstructured-clinical-text-mdx": () => import("./../../../src/templates/news.jsx?__contentFilePath=/builds/website/src/content/news/unstructured-clinical-text.mdx" /* webpackChunkName: "component---src-templates-news-jsx-content-file-path-src-content-news-unstructured-clinical-text-mdx" */),
  "component---src-templates-news-jsx-content-file-path-src-content-news-watch-our-webinar-on-automating-process-monitoring-with-betteromics-mdx": () => import("./../../../src/templates/news.jsx?__contentFilePath=/builds/website/src/content/news/watch-our-webinar-on-automating-process-monitoring-with-betteromics.mdx" /* webpackChunkName: "component---src-templates-news-jsx-content-file-path-src-content-news-watch-our-webinar-on-automating-process-monitoring-with-betteromics-mdx" */),
  "component---src-templates-news-jsx-content-file-path-src-content-news-watch-our-webinar-on-molecular-twin-mdx": () => import("./../../../src/templates/news.jsx?__contentFilePath=/builds/website/src/content/news/watch-our-webinar-on-molecular-twin.mdx" /* webpackChunkName: "component---src-templates-news-jsx-content-file-path-src-content-news-watch-our-webinar-on-molecular-twin-mdx" */),
  "component---src-templates-page-jsx-content-file-path-src-content-about-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/builds/website/src/content/about.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-src-content-about-mdx" */),
  "component---src-templates-page-jsx-content-file-path-src-content-case-studies-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/builds/website/src/content/case-studies.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-src-content-case-studies-mdx" */),
  "component---src-templates-page-jsx-content-file-path-src-content-clinical-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/builds/website/src/content/clinical.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-src-content-clinical-mdx" */),
  "component---src-templates-page-jsx-content-file-path-src-content-diagnostics-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/builds/website/src/content/diagnostics.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-src-content-diagnostics-mdx" */),
  "component---src-templates-page-jsx-content-file-path-src-content-discovery-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/builds/website/src/content/discovery.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-src-content-discovery-mdx" */),
  "component---src-templates-page-jsx-content-file-path-src-content-index-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/builds/website/src/content/index.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-src-content-index-mdx" */),
  "component---src-templates-page-jsx-content-file-path-src-content-manufacturing-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/builds/website/src/content/manufacturing.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-src-content-manufacturing-mdx" */),
  "component---src-templates-page-jsx-content-file-path-src-content-platform-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/builds/website/src/content/platform.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-src-content-platform-mdx" */),
  "component---src-templates-page-jsx-content-file-path-src-content-privacy-policy-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/builds/website/src/content/privacy-policy.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-src-content-privacy-policy-mdx" */),
  "component---src-templates-page-jsx-content-file-path-src-content-terms-of-service-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/builds/website/src/content/terms-of-service.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-src-content-terms-of-service-mdx" */)
}

